import { useGlobalShopContentContext } from '@tofu/shop/providers/global-shop-content-provider'

import { TUseGlobalShopContent } from './use-global-shop-content.types'

export const useGlobalShopContent: TUseGlobalShopContent = () => {
  const { defaultPromoBanner } = useGlobalShopContentContext()

  return {
    defaultPromoBanner
  }
}
