// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// types aren't great here, sorry. This was ported over from the old app and needs rebuilding proplery

import { TNextLinkResolver } from './next-link-resolver.types'

export const nextLinkResolver: TNextLinkResolver = (doc) => {
  if (doc?.link_type) {
    if (!doc || doc.link_type === 'Any') return {}
    if (doc.link_type === 'Web') {
      return { href: doc.url, target: doc.target }
    }
    if (!doc.uid || doc.link_type !== 'Document') return {}
  }

  switch (doc?.type) {
    case 'author':
      return {
        href: `/blog/author/${doc.uid}`
      }
    case 'blog_collection':
      return {
        href: `/blog/collection/${doc.uid}`
      }
    case 'blog_homepage':
      return {
        href: '/blog'
      }
    case 'blog_series':
      return {
        href: `/blog/series/${doc.uid}`
      }
    case 'news':
      return {
        href: `/blog/lifestyle/${doc.uid}`
      }
    case 'recipe':
      return {
        href: `/blog/recipes/${doc.uid}`
      }
    case 'shop_category':
      return {
        href: `/shop/category/${doc.uid}`
      }
    case 'shop_collection':
      return {
        href: `/shop/collection/${doc.uid}`
      }
    case 'single_page': {
      if (doc.uid === 'homepage1')
        return {
          href: '/'
        }
      return {
        href: `/${doc.uid}`
      }
    }
    default:
      // If doc.type is none of these, redirect to homepage,
      return {
        href: '/'
      }
  }
}
