import { RichText } from 'prismic-reactjs'
import NextImage from 'next/image'
import isEmpty from 'lodash/isEmpty'

import { HStack } from '@tofu/shared/ui/atoms/stack'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Container } from '@tofu/shared/ui/atoms/container'
import { linkResolver } from '@tofu/shared/utils/link-resolver'
import { useGlobalShopContent } from '@tofu/shop/hooks/use-global-shop-content'

import { TSliceTextBanner } from './slice-text-banner.types'

const contentTest = [
  {
    type: 'paragraph',
    text: 'Get 30% off 1st box + 15% off 2nd + 3rd boxes with 30PLANTS: Claim',
    spans: [
      {
        start: 61,
        end: 66,
        type: 'hyperlink',
        data: {
          link_type: 'Web',
          url: 'https://allplants.com/shop?discount=30PLANTS'
        }
      }
    ]
  }
]

export const SliceTextBanner: TSliceTextBanner = ({ primary }) => {
  const { statsig } = useGlobalShopContent()
  const { content, background, icon } = primary

  const bannerTest = statsig?.['newPromoOffer'] ? contentTest : content

  if (isEmpty(bannerTest)) return null

  return (
    <Box bg={background} textStyle='body'>
      <Container>
        <HStack
          spacing={3}
          padding='13px 0'
          alignItems='center'
          justifyContent='center'
        >
          {icon?.url && (
            <Box w='24px' height='24px'>
              <NextImage src={icon.url} width='24px' height='24px' />
            </Box>
          )}
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore  */}
          <RichText render={bannerTest} linkResolver={linkResolver} />
        </HStack>
      </Container>
    </Box>
  )
}
